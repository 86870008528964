import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import ImageSlide from "../components/imageSlide";
import SEO from "../components/seo";
import { useMediaQuery } from 'react-responsive';

export default function ProductTemplate({data}) {

    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter } = markdownRemark

    

    const Attributes = () => {
        return frontmatter.attributes.map((e, i) => <li key={"att-"+i}>{e}</li>)
    }

    const PDFIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="26.442" height="30.849" viewBox="0 0 26.442 30.849">
        <path id="Icon_metro-file-pdf" data-name="Icon metro-file-pdf" d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473a1.881,1.881,0,0,0-.379-.706L20.9,4.923a1.88,1.88,0,0,0-.706-.379Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809ZM17.961,20.64a13.1,13.1,0,0,0,1.446.964,17.082,17.082,0,0,1,2.014-.121q2.531,0,3.047.844a.832.832,0,0,1,.034.9.05.05,0,0,1-.017.034l-.034.034v.017q-.1.654-1.222.654a7.1,7.1,0,0,1-1.98-.344,12.552,12.552,0,0,1-2.238-.912,31,31,0,0,0-6.748,1.429q-2.634,4.51-4.166,4.51a1,1,0,0,1-.482-.121L7.2,28.318q-.017-.017-.1-.086a.711.711,0,0,1-.1-.62,3.738,3.738,0,0,1,.964-1.575,8.319,8.319,0,0,1,2.272-1.661.251.251,0,0,1,.4.1.1.1,0,0,1,.034.069q.9-1.463,1.842-3.391a26.274,26.274,0,0,0,1.79-4.51,13.916,13.916,0,0,1-.525-2.746,6.693,6.693,0,0,1,.112-2.195q.189-.689.723-.689h.379a.726.726,0,0,1,.6.258,1.375,1.375,0,0,1,.155,1.171.373.373,0,0,1-.069.138.448.448,0,0,1,.017.138v.516a22.542,22.542,0,0,1-.241,3.305,8.709,8.709,0,0,0,2.513,4.1ZM8.045,27.715A7.6,7.6,0,0,0,10.4,25,9.813,9.813,0,0,0,8.9,26.442,5.722,5.722,0,0,0,8.045,27.715ZM14.9,11.878a5.119,5.119,0,0,0-.034,2.272q.017-.121.121-.757,0-.052.121-.74a.387.387,0,0,1,.069-.138.05.05,0,0,1-.017-.034.035.035,0,0,0-.009-.026.035.035,0,0,1-.009-.026.991.991,0,0,0-.224-.62.05.05,0,0,1-.017.034v.034ZM12.762,23.257a25.25,25.25,0,0,1,4.889-1.394,2.6,2.6,0,0,1-.224-.164,3.083,3.083,0,0,1-.275-.232,9.119,9.119,0,0,1-2.186-3.03,23,23,0,0,1-1.429,3.391q-.516.964-.775,1.429Zm11.121-.275a4.12,4.12,0,0,0-2.41-.413,6.511,6.511,0,0,0,2.135.482,1.67,1.67,0,0,0,.31-.017q0-.017-.034-.052Z" transform="translate(-2.571 -2.204)"/>
    </svg>
  

    const Brochures = ({data, i, title}) => {
        return <li key={"brochure-"+i}><a href={data.publicURL} target="_blank" rel="noopener noreferrer"><PDFIcon /><span>{title}</span></a></li>
        // return data.brochures.edges.map((e, i) => <li key={"brochure-"+i}><a href={e.node.publicURL} target="_blank" rel="noopener noreferrer">Brochure</a></li>)
    }
    

    const Videos = () => {

        return frontmatter.videos.map((e,i) => <div key={`video-${i}`}className="video"><div className="embed-container">
            <iframe  src={`https://www.youtube.com/embed/${e}?rel=0`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>)


    }


    return (
        <Layout pageClass='product-page'>
        <SEO title={frontmatter.title} />
            <main className='main'>
                <div className="inner typography">
                    
                    <div className="product-info">
                        <div className="product-attributes">
                            <h1 dangerouslySetInnerHTML={{__html: frontmatter.htmltitle}}></h1>
                            <ul>
                            {frontmatter.attributes ? <Attributes /> : ''}    
                            </ul>
                            <div className="brochures">
                                <ul>
                                    {data.brochureOne ? 
                                        <Brochures data={data.brochureOne} 
                                                    i="1" 
                                                    title={frontmatter.brochureOne[1]} /> : ''}
                                    {data.brochureTwo ? 
                                        <Brochures 
                                            data={data.brochureTwo} 
                                            i="2" 
                                            title={frontmatter.brochureTwo[1]}/> : ''}
                                    {data.brochureThree ? 
                                        <Brochures 
                                            data={data.brochureThree} 
                                            i="3" 
                                            title={frontmatter.brochureThree[1]}/> : ''}
                                    {data.brochureFour ? 
                                        <Brochures 
                                            data={data.brochureFour} 
                                            i="4" 
                                            title={frontmatter.brochureFour[1]}/> : ''} 

                                </ul>
                                
                            </div>
                            

                            
                            
                    
                        </div>
                        <div className={`product-images ${frontmatter.images.length < 5 ? 'single-line':''}`}>
                            {frontmatter.images.length > 0 ? <ImageSlide data={frontmatter.images} /> : ''}
                        </div>
                    </div>

                    
                    <div className="videos-container">
                        {frontmatter.videos ? <Videos />: ''}
                    </div>
                   
                    
                </div>
            </main> 
        </Layout>
        
    )

}
export const pageQuery = graphql`
  query($url: String!, $brochureOne: String, $brochureTwo: String, $brochureThree: String, $brochureFour: String) {
    markdownRemark(frontmatter: { url: { eq: $url } }) {
        frontmatter {
            title
            htmltitle
            url
            category
            attributes
            videos
            brochureOne
            brochureTwo
            brochureThree
            brochureFour
            
            images {
                childImageSharp {
                    
                    thumbnail: resize(height: 80, width:80,  cropFocus: CENTER, fit: COVER) {
                        src
                    }
                    large: fluid(maxHeight: 400) {
                        src
                    }
                }
                publicURL
            }
            
        }
    }
    brochureOne: file(base: {eq: $brochureOne}) {
           
            publicURL
      }
    brochureTwo: file(base: {eq: $brochureTwo}) {
        
        publicURL
    }
    brochureThree: file(base: {eq: $brochureThree}) {
        
        publicURL
    }
    brochureFour: file(base: {eq: $brochureFour}) {
        
        publicURL
    }
  }

`