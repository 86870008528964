import React, { useState, useEffect } from 'react'


const ImageSlide = ({data}) => {

    const numSlides = 0;
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = data.length
  
    useEffect(() => {

        const timer = setTimeout(() => {
            
            currentSlide >= totalSlides - 1  ? setCurrentSlide(0) : setCurrentSlide(currentSlide+1);
            

        }, 6000)
        return () => clearTimeout(timer)

 
    }, [currentSlide, totalSlides])
    return (

       <div className="image-slider">
            <div className="current-image">
                <img src={data[currentSlide].childImageSharp.large.src} />
            </div>
        <div className="slider-nav">
            <ul>

            
            {data.map((e, i) => {
               return <li key={"slide-nav-"+i}>
                    <button onClick={() => setCurrentSlide(i)}>
                       <img src={e.childImageSharp.thumbnail.src} />
                    </button>
                   </li>
            })}
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            </ul>
        </div>

       </div>
       
    )

}

export default ImageSlide